import {useMoney} from '@shopify/hydrogen-react';
import type {MoneyV2} from '@shopify/hydrogen-react/storefront-api-types';

interface CustomMoneyV2 {
    data: Pick<MoneyV2, "currencyCode" | "amount"> | any;
    showCurrencyCode?: boolean;
    className?: string;
    withoutTrailingZeros?: boolean | false;
    CurrencySymbolWithTrailingZeros?: boolean | false;
}

export function CustomMoney(props: CustomMoneyV2) {
    const {data, showCurrencyCode, className, withoutTrailingZeros, CurrencySymbolWithTrailingZeros} = props;
    const money = useMoney(data);
    return (
        <span className={className}>{CurrencySymbolWithTrailingZeros && money.localizedString} {withoutTrailingZeros && money.withoutTrailingZeros} {showCurrencyCode ? money.currencyCode: ""}</span>
    );
}